<template lang="pug">
.attendance-container
  Popup(
    :visible="learnerAttendance",
    :closeOutside="true",
    :paging.enabled="false",
    :useCustomClose="true",
    title="Attendance",
    width="75%",
    height="75vh",
    @hiding="setRootState({ learnerAttendance: false })"
  )
    .attendance
      DxDataGrid(:dataSource="filteredRegistrants", :showBorders="true", :showRowLines="true")
        DxPaging(:page-size="10")
        DxPager(
          displayMode="adaptive",
          :allowedPageSizes="[5, 10]",
          :showPageSizeSelector="true",
          :showInfo="true",
          :showNavigationButtons="true",
          :visible="true"
        )
        DxColumn(caption="Learner", cellTemplate="nameTemplate")
        DxColumn(caption="In Attendance", cellTemplate="inAttendanceTemplate")
        template(#nameTemplate="{ data }")
          .attendee-name
            span {{ data.data.name }}
        template(#inAttendanceTemplate="{ data }") 
          .attendance-icon
            Icon(:value="iconValue(data.data.present)", :color="iconColor(data.data.present)")
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { CodeUserDetails, BasePopup as Popup } from "@cruciallearning/puddle";
import { mapGetters, mapMutations, mapState } from "vuex";
import DxDataGrid, { DxColumn, DxPager, DxPaging } from "devextreme-vue/data-grid";
import { GetLearners } from "@/types";
import { Icon } from "@cruciallearning/puddle/components";

@Component({
  components: { Popup, Icon, DxDataGrid, DxColumn, DxPaging, DxPager },
  computed: {
    ...mapState(["trainingEventId", "learnerAttendance"]),
  },
  methods: {
    ...mapGetters("UsersModule", ["getLearners"]),
    ...mapMutations(["setRootState"]),
  },
})
export default class AttendanceRecord extends Vue {
  private readonly trainingEventId!: string;
  private readonly getLearners!: GetLearners;

  registrants: CodeUserDetails[] = [];

  async mounted(): Promise<void> {
    this.$api.event.getEvent(this.trainingEventId).then((event) => {
      if (event?.registrationCode) {
        this.registrants = event.registrationCode.registrants;
      }
    });
  }

  get filteredRegistrants(): { name: string; present: boolean }[] {
    if (!this.registrants) return [];
    return this.registrants.map((e) => ({
      name: `${e.firstName} ${e.lastName}`,
      present: this.isUserPresent(e.securityId),
    }));
  }

  get isUserPresent(): (id: string) => boolean {
    return (id: string) => {
      return this.getLearners().findIndex((e) => e.securityId === id) != -1;
    };
  }

  get iconValue(): (present: boolean) => string {
    return (present: boolean) => {
      return present ? "check" : "x";
    };
  }

  get iconColor(): (present: boolean) => string {
    return (present: boolean) => {
      return present ? "var(--success-80)" : "var(--error-50)";
    };
  }
}
</script>
<style lang="scss" scoped>
.attendance {
  max-height: 100%;
  overflow-y: auto;
}
.attendee-name {
  width: 100%;
  text-align: center;
}
.attendee-name span {
  font-size: 1rem;
}
.attendance-icon {
  width: fit-content;
  margin: 0 auto;
  border-radius: 20px;
  vertical-align: middle;
  background-color: whitesmoke;
  padding: 2px;
}
::v-deep .attendance-icon span svg {
  vertical-align: middle;
}
::v-deep .dx-datagrid-rowsview {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
