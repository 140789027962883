<template lang="pug">
.cell-12.dark.layout-grid.presentation
  .waiting-room.cell-10
    .waiting-room-subheader
      a.help-link(
        href="https://help.cruciallearning.com/s/article/CLP-Virtual-Session-Troubleshooting",
        data-name="need-help-link",
        target="_blank"
      ) Need Help?
    .waiting-room-user-settings
      p.title-3(data-name="welcome-message") Welcome, {{ myName }}
      .flex.flex-justify-center.pt-12.pb-12
        template(v-if="wasRemoved")
          div
            p.error You were removed
            p You were removed from the Virtual Classroom by a Trainer or Moderator. If you feel this is an error please refresh the page to reconnect.
        template(v-else-if="wasDisconnected")
          div
            p.warning You were disconnected
            p A network error may have occured or the classroom was accessed from another browser. Please refresh the page to reconnect.
        template(v-else-if="!isMediaAllowed")
          div
            p.subtitle Crucial Learning requires access to your microphone and/or camera. Please refresh the page and allow access when prompted.
            p.subtitle If you continue to see this message it is possible access has been set to "never allow", "block" or another device is currently controling your camera or microphone. Please see your browser's help guide for instructions on restoring media access and close any application that might currently have control of your camera or microphone.
            .flex.flex-column.flex-align-center.pt-8
              Button(wide, text="Refresh", @click="onRefresh")
        template(v-else-if="isUserWaiting")
          Gallery(:dataSource="course.carouselImages", dataName="image-gallery", width="100%")
        template(v-else)
          p.subtitle.pt-12.pb-12 {{ connectingMessage }}
  .cell-2.no-padding.row-min-content.presentation-sidebar
    .no-padding
      .text-center.chat-header
        p.title
          i.icon-message-circle
      ChatList(v-if="!$auth.ADMIN", waitingRoom="true")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { ChatType, Connect, ConnectToClassroom, SetActiveId, UserModel } from "@/types";
import { BaseButton as Button, BaseGallery as Gallery } from "@cruciallearning/puddle";
import ChatList from "@/components/Sidebar/ChatList/BaseChatList.vue";
import authorization from "@/auth/authorization";
import { Course } from "@/types/course";

@Component({
  components: { Button, ChatList, Gallery },
  computed: {
    ...mapState(["isConnected", "isClassroomStarted", "isMediaAllowed", "wasDisconnected", "wasRemoved"]),
    ...mapState("CourseModule", ["course"]),
    ...mapGetters(["isUserWaiting"]),
    ...mapGetters("UsersModule", ["getSelf"]),
  },
  methods: {
    ...mapActions(["connect", "connectToClassroom"]),
    ...mapMutations("ChatModule", ["setActiveId"]),
  },
})
export default class BaseWaitingRoom extends Vue {
  private readonly getSelf!: UserModel | undefined;
  private readonly isClassroomStarted!: boolean;
  private readonly isConnected!: boolean;
  private readonly isMediaAllowed!: boolean;
  private readonly course!: Course;
  private readonly connect!: Connect;

  private readonly connectToClassroom!: ConnectToClassroom;
  private readonly setActiveId!: SetActiveId;

  async created(): Promise<void> {
    const unwatchClassroomConnection = this.$watch("isConnected", async () => {
      if (this.isConnected) await this.connectToClassroom();
      unwatchClassroomConnection();
    });
    if (!this.$auth.ADMIN) this.setActiveId(ChatType.LOBBY);
  }

  get connectingMessage(): string {
    return authorization.isInPerson() ? "Connecting to your in-person experience" : "Connecting to virtual classroom";
  }

  onRefresh(): void {
    window.location.reload();
  }

  get myName(): string | undefined {
    return authorization.myName;
  }
}
</script>

<style lang="scss" scoped>
span {
  color: var(--product-power-of-habit);
}
.chat-header {
  background-color: rgb(49, 49, 49);
}
.help-link {
  color: var(--gray-20);
  position: relative;
  float: right;
}
.waiting-room-subheader {
  width: 100%;
  height: fit-content;
}
.button {
  padding: 12px 11px !important;
}

.waiting-room-user-settings {
  border-radius: 8px;
  padding: 0px 32px;
  padding-bottom: 0;
  width: 100%;
  position: relative;
  text-align: center;
  margin-top: 120px;
}
.title-3 {
  text-transform: capitalize;
}
</style>
